body{
    background-image: url("../../public/background.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
    align-items: center;
}

.dashboard-header h1 {
    margin: 0;
}

.dashboard-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    background: rgba(255,255,255,0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.dashboard-section {
    margin-bottom: 20px;
}

.dashboard-section h3 {
    margin-bottom: 10px;
}

.wallet-list, .token-list {
    list-style-type: none;
    padding: 0;
}

.wallet-item, .token-item {
    background: #ffffff;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wallet-item div, .token-item div {
    flex: 1;
}

.wallet-item button, .token-item button {
    margin-left: 10px;
}

.form-control {
    margin-bottom: 10px;
}

.btn-block {
    width: 100%;
    
}

h2, h4 {
    color: #AB47BC;
}

.btn{
    border: 0px;
    text-transform: uppercase;
    font-size: 18px;
    margin-right: 5px;
    border-radius: 30px;
    padding: 10px 10px;
     text-align: center;
}

.btn-primary, .bg-primary{
    background-color: #AB47BC !important;
    
  }

.btn-primary:hover{
    background-color: #9C27B0;
    
  }
.btn-warning, .bg-warning{
    background-color: #FBC02D !important;
    color: #FFFFFF;
    
  }

.btn-warning:hover{
    background-color: #FF8F00;
    
  }

.btn-secondary, .bg-secondary{
    background: transparent !important;
    color: #AB47BC;
    
  }

.btn-secondary:hover{
    background: transparent;
    color: #9C27B0;
    
  }

  .nav-tabs{
    border-bottom: 0px !important;
  }

  .nav-link{
    color: #9C27B0;
    padding: 10px 40px;
    border-bottom: 0px !important;
    background-color: #ffffff;
  }
  .nav-link:hover{
    color: #9C27B0;
    border-color: #9C27B0;
  }

  .nav-link.active{
    background-color: #AB47BC !important;
    color: #FFFFFF !important;

  }

  .card{
    border: 0px;
  }

  .rounded-pill{
    padding: 8px 14px;
    font-size: 14px;
    margin-right: 5px;
  }

  .list-group{
    height: 500px;
    max-height: 500px;
    overflow: auto;
    border:1px solid #AB47BC;
  }

  .list-group-item{
    color: #333333;
    border: 0px;
    border-radius: 0px !important;
    border-bottom:1px solid #AB47BC;
    padding: 30px 20px;
  }

  h6{
    text-transform: uppercase;
  }

  .smallIcon{
    margin-top: -5px;
    margin-right: 5px;
    margin-left: 5px;
  }