.login-container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.login-form {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    flex: 1;
    max-width: 400px;
}

.login-welcome {
    text-align: center;
    margin-bottom: 1.5rem;
}

.login-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    background: #1c1e29;
    border-radius: 10px;
    padding: 2rem;
    color: white;
    text-align: center;
}

.login-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.login-image-text {
    margin-top: 1.5rem;
}

.btn-block {
    width: 100%;
    margin-top: 1rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-control {
    height: 45px;
    border-radius: 5px;
}
